define("discourse/plugins/discourse-ai/discourse/connectors/topic-more-content/related-topics", ["exports", "@glimmer/component", "@ember/service", "discourse/components/basic-topic-list", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _basicTopicList, _concatClass, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LIST_ID = "related-topics";
  class _default extends _component.default {
    static shouldRender(args1) {
      return args1.model.related_topics?.length;
    }
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [_service.service]))();
    #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
    constructor() {
      super(...arguments);
      this.moreTopicsPreferenceTracking.registerTopicList({
        name: (0, _i18n.default)("discourse_ai.related_topics.pill"),
        id: LIST_ID,
        icon: "discourse-sparkles"
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.moreTopicsPreferenceTracking.removeTopicList(LIST_ID);
    }
    get hidden() {
      return this.moreTopicsPreferenceTracking.selectedTab !== LIST_ID;
    }
    get relatedTopics() {
      return this.args.outletArgs.model.related_topics.map(topic1 => this.store.createRecord("topic", topic1));
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          role="complementary"
          aria-labelledby="related-topics-title"
          id="related-topics"
          class={{concatClass "more-topics__list" (if this.hidden "hidden")}}
        >
          <h3 id="related-topics-title" class="more-topics__list-title">
            {{i18n "discourse_ai.related_topics.title"}}
          </h3>
    
          <div class="topics">
            <BasicTopicList @topics={{this.relatedTopics}} />
          </div>
        </div>
      
    */
    {
      "id": "H0ELngHR",
      "block": "[[[1,\"\\n    \"],[10,0],[14,\"role\",\"complementary\"],[14,\"aria-labelledby\",\"related-topics-title\"],[14,1,\"related-topics\"],[15,0,[28,[32,0],[\"more-topics__list\",[52,[30,0,[\"hidden\"]],\"hidden\"]],null]],[12],[1,\"\\n      \"],[10,\"h3\"],[14,1,\"related-topics-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_ai.related_topics.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@topics\"],[[30,0,[\"relatedTopics\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/topic-more-content/related-topics.js",
      "scope": () => [_concatClass.default, _i18n.default, _basicTopicList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = _default;
});